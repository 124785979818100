import React, { useEffect, useState } from 'react';
import './L.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [attempts, setAttempts] = useState(0);
  const navigate = useNavigate();

  const isUserLoggedIn = Cookies.get('oglqldresw');
  const isUserBlocked = Cookies.get('userBlocked');

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate('/');
    }
  }, [navigate, isUserLoggedIn]);

  useEffect(() => {
    if (isUserBlocked) {
      alert('You are blocked for 1 hour');
      navigate('/error');
    }
  }, [navigate, isUserBlocked]);

  const handleLogin = (e) => {
    e.preventDefault();

    if (email === 'mk@git.in' && password === 'tastyCookie') {
      console.log('Logged in');
      Cookies.set("oglqldresw", true, { expires: 1 / 24 });
      navigate('/crudadmin');
    } else {
      setAttempts(prevAttempts => prevAttempts + 1);
      console.log(`Login attempts: ${attempts + 1}`);
    }

    if (attempts + 1 >= 3) {
      alert('You are blocked for 1 hour');
      Cookies.set("userBlocked", true, { expires: 1 / 24 });
      navigate('/error');
    }
  };

  const handleBack = () => {
    console.log('Back button clicked');
    navigate('/'); // This will navigate to the previous page in history
  };

  // If the user is logged in, don't render the login form
  if (isUserLoggedIn) {
    return null;
  }

  return (
    <div className='log'>
      <button className="btnm" onClick={handleBack}>Back</button>

      <form className="form">
        <p className="heading">Login</p>
        <input
          className="input"
          placeholder="Username"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="input"
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="btn" onClick={handleLogin}>Log In</button>
      </form>
    </div>
  );
}

export default Login;
