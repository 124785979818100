import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function ProtectedAuth({ Component }) {
  const navigate = useNavigate();
  const isAuthenticated = Cookies.get('oglqldresw');

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [navigate, isAuthenticated]);

  return isAuthenticated ? <Component /> : null;
}

export default ProtectedAuth;
