import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './Auth/ProtectedRoute';
import ProtectedAuth from './Auth/ProtectedAuth';
import Home from './Component/Home';
import Login from './Auth/Login';
import Create from './CRUD/Create';
import ErrorPage from './Component/Error';
import AuthUser from './Component/AuthUser';
import MovieView from './CRUD/MovieView';
import Crud from './CRUD/Crud';
import MovieViewDel from './CRUD/MovieViewDel';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/authenNormalUser' element={<AuthUser />} />
        <Route path='/' element={<ProtectedRoute Component={Home} />} />
        <Route path='/error' element={<ErrorPage />} />
        <Route path='/login' element={<Login />} />
        <Route path='/create' element={<ProtectedAuth Component={Create} />} />
        <Route path="/movie/:id" element={<ProtectedRoute Component={MovieView} />} />
        <Route path='/crudadmin' element={<ProtectedAuth Component={Crud} />} />
        <Route path="/moviedel/:id" element={<ProtectedRoute Component={MovieViewDel} />} />
      </Routes>
    </Router>
  );
}

export default App;
