import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, addDoc, query, where, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import app from '../Firebase';
import './C.css';

function Create() {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({ image: 0, video: 0 });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();
  const db = getFirestore(app);
  const storage = getStorage(app);

  const handleBack = () => {
    navigate(-1);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleVideoChange = (e) => {
    if (e.target.files[0]) {
      setVideo(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!title || !image || !video) {
      setError('Please fill in all fields and upload both files.');
      return;
    }

    setUploading(true);
    setError('');
    setSuccess('');

    try {
      // Upload image
      const imageRef = ref(storage, `images/${image.name}`);
      const imageUploadTask = uploadBytesResumable(imageRef, image);

      imageUploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Image upload progress: ', progress); // Debugging progress
          setUploadProgress(prev => ({ ...prev, image: progress }));
        },
        (err) => {
          console.error('Error uploading image: ', err.message); // Debugging error
          setError('Error uploading image: ' + err.message);
        },
        async () => {
          const imageURL = await getDownloadURL(imageUploadTask.snapshot.ref);

          // Upload video
          const videoRef = ref(storage, `videos/${video.name}`);
          const videoUploadTask = uploadBytesResumable(videoRef, video);

          videoUploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Video upload progress: ', progress); // Debugging progress
              setUploadProgress(prev => ({ ...prev, video: progress }));
            },
            (err) => {
              console.error('Error uploading video: ', err.message); // Debugging error
              setError('Error uploading video: ' + err.message);
            },
            async () => {
              const videoURL = await getDownloadURL(videoUploadTask.snapshot.ref);

              // Save metadata to Firestore
              await addDoc(collection(db, 'media'), {
                title,
                imageURL,
                videoURL,
                searchTerm: searchTerm.toLowerCase(),
                uploadedAt: new Date(),
              });

              setSuccess('Files uploaded and metadata saved successfully!');
              setImage(null);
              setVideo(null);
              setTitle('');
            }
          );
        }
      );
    } catch (err) {
      console.error('Error uploading files: ', err.message); // Debugging error
      setError('Error uploading files: ' + err.message);
    } finally {
      setUploading(false);
    }
  };

  const handleSearch = async () => {
    if (!searchTerm) return;

    const q = query(
      collection(db, 'media'),
      where('title', '>=', searchTerm),
      where('title', '<=', searchTerm + '\uf8ff')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSearchResults(results);
    }, (error) => {
      console.error('Error fetching search results: ', error.message); // Debugging error
      setError('Error fetching search results: ' + error.message);
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm, handleSearch]);

  return (
    <div className='create-map'>
      <div className="form-type">
      <h1>Upload The File</h1>
        <label htmlFor="title">
          Title
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
        <label htmlFor="uploadimg">
          Upload Image
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </label>
        <label htmlFor="uploadvid">
          Upload Video
          <input
            type="file"
            accept="video/*"
            onChange={handleVideoChange}
          />
        </label>
        <label htmlFor="videosearchhelper">
          Search Keyword:
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </label>
      <button className="btns" type="button" onClick={handleUpload} disabled={uploading}>
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
      </div>
      <br /><br />
      <button className="btn" type="button" onClick={handleBack}>
        Back
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
      {uploading && (
        <div>
          <h3>Upload Progress</h3>
          <p>Image: {Math.round(uploadProgress.image)}%</p>
          <p>Video: {Math.round(uploadProgress.video)}%</p>
        </div>
      )}
      {searchResults.length > 0 && (
        <div>
          <h2>Search Results</h2>
          <ul>
            {searchResults.map(result => (
              <li key={result.id}>
                <p><strong>Title:</strong> {result.title}</p>
                <p><strong>Image URL:</strong> <a href={result.imageURL} target="_blank" rel="noopener noreferrer">{result.imageURL}</a></p>
                <p><strong>Video URL:</strong> <a href={result.videoURL} target="_blank" rel="noopener noreferrer">{result.videoURL}</a></p>
                <p><strong>Keyword for search: </strong>{result.searchTerm}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Create;
