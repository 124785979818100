import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import app from '../Firebase'; // Adjust the import based on your file structure4
import './View.css'

function MovieViewDel() {
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [videoURL, setVideoURL] = useState(null);
    const db = getFirestore(app);
    const storage = getStorage(app);
    const nav = useNavigate();

    useEffect(() => {
        const fetchItem = async () => {
            try {
                const docRef = doc(db, 'media', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setItem(data);

                    if (data.videoPath) {
                        const videoRef = ref(storage, data.videoPath);
                        const url = await getDownloadURL(videoRef);
                        console.log('Video URL:', url); // Log the URL
                        setVideoURL(url);
                    }
                } else {
                    setError('No such document!');
                }
            } catch (err) {
                setError('Error fetching item: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchItem();
    }, [id, db, storage]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="view-page">
            <div className='detail-page'>
                <button className='btn' onClick={() => {
                    nav(-1);
                }}>Back</button>
                <div className="sao">
                    <img src={item.imageURL} alt={item.title} />
                    <div className="ge">
                        <div className="ew">
                            <h1>{item.title}</h1>
                            <p>{item.description}</p>
                        </div>
                        <div className="container">
                            {videoURL ? (

                                <div className='video-container'>
                                    <video width="600" controls>
                                        <source src={videoURL} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ) : (
                                <> <a href={item.videoURL} target="_blank" rel="noopener noreferrer">Watch Here</a>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MovieViewDel;
