import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, Link } from 'react-router-dom';
import { getFirestore, collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import app from '../Firebase'; // Adjust the import based on your file structure
import './H2.css';

function Crud() {
  const [checkAdminLog, setCheckAdminLog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [listOfData, setListOfData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const userCookie = Cookies.get("user");
  const adminCookie = Cookies.get("oglqldresw");
  const db = getFirestore(app);

  useEffect(() => {
    if (!userCookie) {
      navigate('/authenNormalUser');
    }
  }, [navigate, userCookie]);

  useEffect(() => {
    if (adminCookie) {
      setCheckAdminLog(true);
    } else {
      setCheckAdminLog(false);
    }
  }, [adminCookie]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'media'));
        const documents = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setListOfData(documents);
      } catch (err) {
        setError('Error fetching all data: ' + err.message);
        console.log('Error fetching all data:', err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [db]);

  useEffect(() => {
    if (searchTerm) {
      const filteredResults = listOfData.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setResults(filteredResults);
    } else {
      setResults([]);
    }
  }, [searchTerm, listOfData]);

  const deleteCookie = () => {
    Cookies.remove("user");
    navigate('/authenNormalUser');
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        await deleteDoc(doc(db, 'media', id));
        setListOfData(prevList => prevList.filter(item => item.id !== id));
        setResults(prevResults => prevResults.filter(item => item.id !== id));
      } catch (err) {
        setError('Error deleting item: ' + err.message);
        console.log('Error deleting item:', err.message);
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleItemClick = (id) => {
    navigate(`/movie/${id}`);
  };

  return (
    <div className='home-page2'>
      <div className="header2">
        <div className="opt2">
          <Link to={'/'}>MOVIE BUZZ</Link>
        </div>
        <div className="more-opt2">
          <button onClick={deleteCookie}>Log out</button>
          <div className="div2">
            <input
              type="text"
              placeholder="Search videos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="pt2">
              <ul className='total-list2'>
                {results.map(result => (
                  <li key={result.id} className='list2'  onClick={() => handleItemClick(result.id)}>
                    <img src={result.imageURL} alt="" />
                    <p>{result.title}</p>
                    <button onClick={() => handleDelete(result.id)}>Delete</button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {checkAdminLog ? (
            <Link to={'/crudadmin'} id='cc'>MX LOGED</Link>
          ) : (
            <Link to={'/'} id='cc'>ADMIN LOGIN</Link>
          )}
        </div>
      </div>
      <div className="body-page-render2">
        <div className="body-page2">
        <Link to={'/create'}>Create</Link>
          <h1>Admin Page</h1>
          <div className="cards2">
            <ul>
              {listOfData.slice(0, 8).map(item => (
                <li key={item.id} className='single2' onClick={() => handleItemClick(item.id)}>
                  <img className='img2' src={item.imageURL} alt="" />
                  <h1 className='title2'>{item.title}</h1>
                  <p className='description2'>{item.description}</p>
                  <button onClick={() => handleDelete(item.id)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Crud;
