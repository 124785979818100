import './S.css'
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import app from '../Firebase'

function AuthUser() {

    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [counter, setCounter] = useState(0);
    const nav = useNavigate();
    const auth = getAuth(app);

    const logHandle = (e) => {
        e.preventDefault();

        signInWithEmailAndPassword(auth, email, pass).then((userCredential)=>{
            const user = userCredential.user;
            Cookies.set('user', JSON.stringify(user), {expires : 3 / 24});
            nav('/')
        }).catch((error)=> {
            alert(error.message);
            setCounter(counter + 1)
        })
       
    }

    useEffect(()=>{
        const cook = Cookies.get('user');
        if(cook){
            nav('/')
        }
    })

    return (
        <div className='nrmAuth'>
            <form className="form">
                <span className="heading">Sign In</span>

                <span className="Mail">E-Mail</span>
                <input placeholder="Enter E-Mail" type="text" className="input" value={email} onChange={(e) => setEmail(e.target.value)} />
                <span className="Password">Password</span>
                <input placeholder="Enter Password" type="text" className="input" value={pass} onChange={(e) => setPass(e.target.value)} />
                <button onClick={logHandle}>Log In</button>
            </form>

        </div>
    )
}

export default AuthUser
