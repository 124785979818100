import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function ProtectedRoute({ Component }) {
  const navigate = useNavigate();
  const isUserBlocked = Cookies.get('userBlocked');

  useEffect(() => {
    if (isUserBlocked) {
      navigate('/error');
    }
  }, [navigate, isUserBlocked]);

  return <Component />;
}

export default ProtectedRoute;
