import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, Link } from 'react-router-dom';
import { getFirestore, collection,  getDocs } from 'firebase/firestore';
import app from '../Firebase'; // Adjust the import based on your file structure
import './H.css';
import { getId } from 'firebase/installations';

function Home() {
  const [checkAdminLog, setCheckAdminLog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [listOfData, setListOfData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const userCookie = Cookies.get("user");
  const adminCookie = Cookies.get("oglqldresw");
  const db = getFirestore(app);

  useEffect(() => {
    if (!userCookie) {
      navigate('/authenNormalUser');
    }
  }, [navigate, userCookie]);

  useEffect(() => {
    if (adminCookie) {
      setCheckAdminLog(true);
    } else {
      setCheckAdminLog(false);
    }
  }, [adminCookie]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'media'));
        const documents = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setListOfData(documents);
      } catch (err) {
        setError('Error fetching all data: ' + err.message);
        console.log('Error fetching all data:', err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [db]);

  useEffect(() => {
    if (searchTerm) {
      const filteredResults = listOfData.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setResults(filteredResults);
    } else {
      setResults([]);
    }
  }, [searchTerm, listOfData]);

  const deleteCookie = () => {
    Cookies.remove("user");
    navigate('/authenNormalUser');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleItemClick = (id) => {
    navigate(`/movie/${id}`);
  };

  return (
    <div className='home-page'>
      <div className="header">
        <div className="opt">
          <h1>MOVIE BUZZ</h1>
        </div>
        <div className="more-opt">
          <button onClick={deleteCookie}>Log out</button>
          <div className="div">
            <input
              type="text"
              placeholder="Search videos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="pt">
              <ul className='total-list'>
                {results.map(result => (
                  <li key={result.id} className='list' onClick={() => handleItemClick(result.id)}>
                    <img src={result.imageURL} alt="" />
                    <p>{result.title}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {checkAdminLog ? (
            <Link to={'/crudadmin'} id='cc'>MX LOGED</Link>
          ) : (
            <Link to={'/crudadmin'} id='cc'>ADMIN LOGIN</Link>
          )}
        </div>
      </div>
      <div className="body-page-render">
        <div className="body-page">
          <h1>Latest Movie</h1>
          <div className="cards">
            <ul>
              {listOfData.slice(0, 8).map(item => (
                <li key={item.id} className='single' onClick={() => handleItemClick(item.id)}>
                  <img className='img' src={item.imageURL} alt="" />
                  <h1 className='title'>{item.title}</h1>
                  <p className='description'>{item.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
