import React from 'react'

function Error() {
  return (
    <div>
      <br /><br /><hr />
      <h1>You cannot access the page for now <br /> Come after 1 Hour</h1>
      <hr />
    </div>
  )
}

export default Error
